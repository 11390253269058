import React from 'react';
import ProfilePicture from "routes/app/components/ProfilePicture"

const ProfileSection = ({ match, handleToggleExpand, expand }) => {
    
  return (
    <div className={"flex flex-row flex-wrap items-center p-5 "+(expand?"":"cursor-pointer hover:bg-gray-100")} onClick={handleToggleExpand}>
        {/* Profile Image */}
        <ProfilePicture userId={match.tripDetails.uid}/>

        {/* Match Summary */}
        <div className='ml-2'>
          <h3 className="text-xl font-bold">{match.name}</h3>
          {/* <p className="text-yellow-500"><strong>Rating:</strong> {match.rating} / 5</p> */}
          <p className="text-green-600"><strong>Route Overlap: {match.routeOverlap}%</strong> - According to set radius.</p>
          <p className="text-green-600"><strong>Time Match: {match.timeMatch}%</strong> - According to one week offset.</p>
        </div>
      </div>
  );
};

export default ProfileSection;
