// TitleComponent.js
import { getTimeString } from 'utils/timeUtils';

const TitleComponent = ({ tripData, ownData }) => {  
    const startLocationName = tripData.startLocationName;
    const endLocationName = tripData.endLocationName;
    const timeString = getTimeString(tripData);
  
    let ownStartLocationName, ownEndLocationName, ownTimeString;
    if (ownData) {
      ownStartLocationName = ownData.startLocationName;
      ownEndLocationName = ownData.endLocationName;
      ownTimeString = getTimeString(ownData);
    }
  
    // Determine the color class for tripData based on the presence of ownData
    const tripDataColorClass = false && ownData ? "text-red-500" : "text-black";
  
    return (
      <h2 className={"text-xl font-bold text-center "+(ownData?"":"mb-8")}>
        <span className={tripDataColorClass}>
            {startLocationName.split(",")[0]} → {endLocationName.split(",")[0]}
        </span>
        <br />
        <span className={tripDataColorClass}>
            {timeString}
        </span>
        {/* {ownData && (
          <>
            <br />
            <span className="text-blue-500">
                {ownStartLocationName} → {ownEndLocationName}<br/>
            </span>
            <span className="text-blue-500">
                {ownTimeString}
            </span>
          </>
        )} */}
      </h2>
    );
};
  
export default TitleComponent;
  